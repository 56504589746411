export const GET_APPLICATION_INTERACTION = 'GET_APPLICATION_INTERACTION';
export const getAppInteraction = (appInteractionData) => ({
    type: GET_APPLICATION_INTERACTION,
    payload: { appInteractionData }
});

export const SET_APPLICATION_INTERACTIONS = 'SET_APPLICATION_INTERACTIONS';
export const setApplicationInteractions = (applicationInteractions) => ({
    type: SET_APPLICATION_INTERACTIONS,
    payload: { applicationInteractions }
});


