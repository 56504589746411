import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcImage,
    DrcPageNotFound,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcMain,
    DrcPanel,
    DrcMediaQueries,
    Helmet,
    DrcVersionInfo,
    DrcPageLogin,
    DrcImplicitCallback,
    DrcLoading,
    DrcBackdrop
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';

import LogoPng from './images/Logo_Small_Transparent.png';
import LogoWebP from './images/Logo_Small_Transparent.webp';
import BackgroundPng from './images/Field_Black.png';
import BackgroundWebP from './images/Field_Black.webp';

import {
    hideCommonDialogAction,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    addInfo,
    setCommonDialogAction,
    showToast,
    serviceWorkerUpdate
} from './actions/actions';
import LoggingUtilities from './data/LoggingUtilities';
import APP_TRANSLATIONS from './data/appTranslations';
import Directory from './pages/Directory';
import ApplicationFlows from './pages/ApplicationFlows';
import LogOut from './pages/LogOut';
import ApplicationImpacts from './pages/ApplicationImpacts';
import ApplicationInteractions from './pages/ApplicationInteractions';

import { Landscape, SubdirectoryArrowRight, DirectionsRun } from '@material-ui/icons';
import EventIcon from '@material-ui/icons/Event';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import StorageIcon from '@material-ui/icons/Storage';

const Releases = lazy(() => import('./pages/Releases'));
// const HealthCheck = lazy(() => import('./pages/HealthCheck'));
const MasterData = lazy(() => import('./pages/Admin/MasterData'));
const ApplicationsMaster = lazy(() => import('./pages/Admin/ApplicationsMaster'));
const DepartmentsMaster = lazy(() => import('./pages/Admin/DepartmentsMaster'));
const ProcessesMaster = lazy(() => import('./pages/Admin/ProcessesMaster'));
const ProcessImpactsMaster = lazy(() => import('./pages/Admin/ProcessImpactsMaster'));
const ApplicationInteractionMaster = lazy(() => import('./pages/Admin/ApplicationInteractionMaster'));

const DEV_GROUPS = window.config.OKTA_DEV || [];
const ADMIN_GROUPS = window.config.OKTA_ADMIN_GROUPS || [];

const SITE_NAME = 'AppLand';

const allLinks = [
    {
        title: 'Landscapes',
        url: '/Landscapes/AppLandscape/',
        icon: <Landscape />
    },
    // {
    //     title: 'Flows',
    //     url: '/Flows/',
    //     icon: <SubdirectoryArrowRight />
    // },
    {
        title: 'Application Process Impacts',
        url: '/ApplicationImpacts/',
        icon: <VerticalSplitIcon />
    },
    {
        title: 'Application Interactions',
        url: '/ApplicationInteractions/',
        icon: <VerticalSplitIcon />
    },
    {
        title: 'Master Data',
        url: '/Admin/MasterData/',
        icon: <StorageIcon />,
        subLinks: [
            { title: 'Applications', url: '/Admin/ApplicationsMaster/' },
            { title: 'Departments', url: '/Admin/DepartmentsMaster/' },
            { title: 'Processes', url: '/Admin/ProcessesMaster/' },
            { title: 'Process Impacts', url: '/Admin/ProcessImpactsMaster/' },
            { title: 'Application Interactions', url: '/Admin/ApplicationInteractionMaster/' }
        ]
    },
    {
        title: 'Releases',
        url: '/Releases/',
        icon: <EventIcon />
    },
    { title: 'Sign Out', url: '/LogOut/', icon: <DirectionsRun /> }
];

var styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },

        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        }
    },
    logoLink: {
        left: 'calc(50vw - 70px)',
        position: 'absolute'
    },
    logo: {
        height: theme.spacing(5),
        marginRight: theme.spacing(3),
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            filter: 'none'
        }
    },
    header: {
        '& .toolbar': {
            minHeight: theme.spacing(8),
            height: theme.spacing(8),
            maxHeight: theme.spacing(8),
            textShadow: 'none'
        },
        '& .title': {
            textShadow: 'none',
            fontSize: 22
        },
        '& .appBar': {
            boxShadow: 'none',
            fontSize: theme.spacing(2.5)
        },
        '& .logOut': {
            right: 0,
            position: 'absolute',
            top: theme.spacing(1.5),
            paddingRight: theme.spacing(6),
            '& a:hover': {
                backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 80)
            }
        },
        '& .logOutLink': {
            color: DuThemeUtilities.DefaultColors.primary.green,
            border: 'none'
        },
        '& .logOutTitle': {
            paddingLeft: theme.spacing(1),
            fontSize: theme.spacing(2)
        },
        '@media print': {
            display: 'none'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...APP_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        //Removed for now, but should add this back
        //this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/Landscapes/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        var { classes } = this.props;

        return (
            <DrcThemeProvider theme={this.state.theme}>
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>AppLand: {this.props.pageTitle.length > 0 ? this.props.pageTitle : 'Home'}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <DrcSecureHeader
                            title={this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}
                            allLinks={allLinks}
                            fullWidth={true}
                            logo={
                                <Link to="/Landscapes/AppLandscape/" className={classes.logoLink}>
                                    <DrcImage src={LogoPng} webp={LogoWebP} className={classes.logo} alt="Driscolls Logo" />
                                </Link>
                            }
                            className={classes.header}
                        ></DrcSecureHeader>
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <DrcPageLogin
                                            {...props}
                                            poly
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                            redirect={'/Landscapes/AppLandscape/'}
                                        />
                                    )}
                                />
                                <DrcSecureGroupRoute path="/Landscapes/:type/" ignorePath component={Directory} groupsAllowed={DEV_GROUPS} />
                                <DrcSecureGroupRoute path="/Flows/" exact component={ApplicationFlows} groupsAllowed={DEV_GROUPS} />
                                <DrcSecureGroupRoute path="/Admin/MasterData/" exact component={MasterData} groupsAllowed={ADMIN_GROUPS} />
                                <DrcSecureGroupRoute
                                    path="/Admin/ApplicationsMaster/"
                                    exact
                                    component={ApplicationsMaster}
                                    groupsAllowed={ADMIN_GROUPS}
                                />
                                <DrcSecureGroupRoute
                                    path="/Admin/DepartmentsMaster/"
                                    exact
                                    component={DepartmentsMaster}
                                    groupsAllowed={ADMIN_GROUPS}
                                />
                                <DrcSecureGroupRoute path="/Admin/ProcessesMaster/" exact component={ProcessesMaster} groupsAllowed={ADMIN_GROUPS} />
                                <DrcSecureGroupRoute
                                    path="/Admin/ProcessImpactsMaster/"
                                    exact
                                    component={ProcessImpactsMaster}
                                    groupsAllowed={ADMIN_GROUPS}
                                />
                                <DrcSecureGroupRoute
                                    path="/Admin/ApplicationInteractionMaster/"
                                    exact
                                    component={ApplicationInteractionMaster}
                                    groupsAllowed={ADMIN_GROUPS}
                                />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={DEV_GROUPS} />
                                <DrcSecureGroupRoute
                                    path="/ApplicationImpacts/:appId/:appName/"
                                    exact
                                    component={ApplicationImpacts}
                                    groupsAllowed={DEV_GROUPS}
                                />
                                <DrcSecureGroupRoute
                                    path="/ApplicationImpacts/"
                                    exact
                                    ignorePath
                                    component={ApplicationImpacts}
                                    groupsAllowed={DEV_GROUPS}
                                />
                                <DrcSecureGroupRoute
                                    path="/ApplicationInteractions/"
                                    exact
                                    component={ApplicationInteractions}
                                    groupsAllowed={DEV_GROUPS}
                                />
                                <DrcSecureGroupRoute
                                    path="/ApplicationInteractions/:appId/:appName/"
                                    exact
                                    component={ApplicationInteractions}
                                    groupsAllowed={DEV_GROUPS}
                                />

                                <Route
                                    path="/implicit/callback"
                                    render={(props) => (
                                        <DrcImplicitCallback
                                            {...props}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                            redirect={'/Landscapes/AppLandscape/'}
                                        />
                                    )}
                                />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={true} />
                    <DrcBackdrop isLoading show={this.props.showLoadingBackdrop} loadingMessage={this.props.loadingMessage} />
                    <DrcDialog
                        isError={this.props.commonDialog.isMessage}
                        title={this.props.commonDialog.title}
                        open={this.props.commonDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.commonDialog.content}
                    </DrcDialog>
                    <DrcDialog
                        title={'New version available'}
                        open={this.props.serviceWorkerUpdated}
                        buttons={
                            <>
                                <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                    Ok
                                </DrcButton>
                            </>
                        }
                    >
                        <div>Refresh to update your app?</div>
                    </DrcDialog>
                </Router>
                {this.props.toastMessage && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                        open={this.props.toastMessage !== ''}
                        autoHideDuration={3000}
                        onClose={this.handleCloseNotification}
                        message={<span id="message-id">{this.props.toastMessage}</span>}
                    />
                )}
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated,
        showLoadingBackdrop: state.rootReducer.showLoadingBackdrop
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
