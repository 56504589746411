import React, { Component } from 'react';
import BorderWrapper from './BorderWrapper';
import { Cloud, Settings } from '@material-ui/icons';
import MuleLogoPng from '../images/muleSoft.png';
import MuleLogoWebp from '../images/muleSoft.webp';
import AwsLogoPng from '../images/AWS.png';
import AwsLogoWebp from '../images/AWS.webp';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DrcImage } from '@driscollsinc/driscolls-react-components';

var styles = () => ({
    containerWrapperStyle: {
        borderWidth: '1px',
        borderRadius: '10px',
        borderStyle: 'solid',
        borderColor: DuThemeUtilities.DefaultColors.primary.green,
        backgroundColor: '#DDD',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#111'
        }
    },
    commonStyle: {
        borderWidth: '1px',
        height: '15px',
        width: '15px',
        borderStyle: 'solid',
        margin: '0 10px'
    },
    legend: {
        fontWeight: 'bold',
        margin: 10
    },
    cotsWrapper: {
        borderWidth: 2,
        borderColor: DuThemeUtilities.DefaultColors.primary.green,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 75),
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 25)
        }
    },
    customWrapper: {
        borderWidth: 2,
        borderColor: DuThemeUtilities.DefaultColors.primary.yellow,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.yellow), 85),
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.yellow), 15)
        }
    },
    cloudLogo: {
        marginRight: '10px',
        fill: 'rgb(0,118,165)'
    },
    muleLogo: {
        marginRight: 10,
        height: 15
    },
    interactionStyle: {
        borderColor: 'rgb(111, 80, 145)',
        backgroundColor: 'rgba(111, 80, 145, .8)'
    },
    legendSpacing: {
        margin: '0 10px'
    },
    wrapper: {
        marginBottom: '10px'
    }
});

class Legend extends Component {
    buildLegendsItems() {
        const { classes, applicationDetailLegendsData, typeLookupsData } = this.props;
        if (!applicationDetailLegendsData.Results || !typeLookupsData.Results) {
            return null;
        }
        const applicationDetails = applicationDetailLegendsData.Results;
        const typeLookups = typeLookupsData.Results;
        const applicationDetailsWithoutDuplicates = applicationDetails.reduce((acc, current) => {
            const x = acc.find((item) => item.TypeId === current.TypeId && item.TypeLookupId === current.TypeLookupId);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        const legends = applicationDetailsWithoutDuplicates.map((item) => {
            const typeLookup = typeLookups.find((tl) => tl.TypeLookupId === item.TypeLookupId);
            if (typeLookup) {
                return typeLookup.Label;
            }
        });
        const legendItems = legends.map((legend, index) => {
            const legendKey = `legend-${index}`;
            const icon =
                legend === 'Cloud' ? (
                    <Cloud className={classes.cloudLogo} />
                ) : legend === 'DIS' ? (
                    <Settings className={classes.customWrapper} />
                ) : legend === 'COTS' ? (
                    <div className={`${classes.cotsWrapper} ${classes.commonStyle}`} />
                ) : legend === 'Custom' ? (
                    <div className={`${classes.customWrapper} ${classes.commonStyle}`} />
                ) : legend === 'Mulesoft' ? (
                    <DrcImage src={MuleLogoPng} webp={MuleLogoWebp} className={classes.muleLogo} alt="Mule Logo" />
                ) : (
                    <DrcImage src={AwsLogoPng} webp={AwsLogoWebp} className={classes.muleLogo} alt="AWS Logo" />
                );
            return (
                <div key={legendKey} className={`row ${classes.wrapper}`}>
                    {icon}
                    <span>{legend}</span>
                </div>
            );
        });
        return legendItems;
    }

    render() {
        const { classes } = this.props;
        return (
            <BorderWrapper className={classes.containerWrapperStyle}>
                <div className={classes.legend}>Legend</div>
                {this.buildLegendsItems()}
                {/*                 <div className={`row ${classes.wrapper}`}>
                    <div className={`${classes.cotsWrapper} ${classes.commonStyle}`} />
                    <span>COTS</span>
                </div>
                <div className={`row ${classes.wrapper}`}>
                    <div className={`${classes.customWrapper} ${classes.commonStyle}`} />
                    <span>Custom</span>
                </div>

                <div className={classes.wrapper}>
                    <Cloud className={classes.cloudLogo} />
                    Cloud
                </div>
                <div className={classes.wrapper}>
                    <Settings className={classes.legendSpacing} />
                    DIS Integrated
                </div>
                <div className={classes.wrapper}>
                    <DrcImage src={MuleLogoPng} webp={MuleLogoWebp} className={classes.muleLogo} alt="Mule Logo" />
                    Mulesoft Integrated
                    {this.props.filters.displayOption.value === 'App Interactions' && (
                        <>
                            <BorderWrapper className={`${classes.interactionStyle} ${classes.commonStyle}`} />
                            <div>Interaction</div>
                        </>
                    )}
                </div>
                <div className={classes.wrapper}>
                    <DrcImage src={AwsLogoPng} webp={AwsLogoWebp} className={classes.muleLogo} alt="AWS Logo" />
                    AWS APIs Integrated
                </div> */}
            </BorderWrapper>
        );
    }
}

export function mapStateToProps(state) {
    return {
        filters: state.SelectedFilterReducer,
        applicationDetailLegendsData: state.ApplicationImpactsReducer.applicationDetailLegendsData,
        typeLookupsData: state.ApplicationImpactsReducer.typeLookupsData
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Legend));
