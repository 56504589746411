import React, { Component } from 'react';
import { connect } from 'react-redux';
import BUHeader from '../component/BUHeader';
import Department from '../component/Department';
import Legend from '../component/Legend';
import { ArrowUpward } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

var styles = () => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    svgArrow: {
        fontSize: '80px',
        width: '25%'
    }
});
class IntegratedApps extends Component {
    render() {
        const { classes, deptAppData } = this.props;

        let BUs;
        if (this.props.filters.businessUnit.value === 'All') {
            BUs =
                deptAppData &&
                deptAppData.map((deptName) => {
                    const deptValue = deptName.Applications || [];
                    const name = deptName.DepartmentName;

                    return (
                        <Department
                            title={name}
                            key={name}
                            appData={deptValue.filter((app) => app.HasDisIntegration == true)}
                            margin="10px 2px 10px 0"
                        />
                    );
                });
        } else {
            let filteredData = deptAppData.filter((deptName) => deptName.DepartmentName === this.props.filters.businessUnit.value);
            BUs =
                filteredData &&
                filteredData.map((deptName) => {
                    const deptValue = deptName.Applications || [];
                    const name = deptName.DepartmentName;

                    return (
                        <Department
                            title={this.props.filters.businessUnit.label}
                            key={name}
                            appData={deptValue.filter((app) => app.HasDisIntegration == true)}
                            margin="10px 2px 10px 0"
                        />
                    );
                });
        }

        return (
            <div>
                <BUHeader intLayer={2} />
                <div>
                    <ArrowUpward className={classes.svgArrow} />
                    <ArrowUpward className={classes.svgArrow} />
                    <ArrowUpward className={classes.svgArrow} />
                    <ArrowUpward className={classes.svgArrow} />
                </div>
                <div>{BUs}</div>
                <Legend margin="10px 0 10px 2px" />
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        deptAppData: state.ApplicationImpactsReducer.deptAppData,
        filters: state.SelectedFilterReducer,
        data: state.AppMasterReducer
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(IntegratedApps));
