import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DrcSelect, DrcMain, DrcLegend, DrcLoading, DrcMediaQueries } from '@driscollsinc/driscolls-react-components';
import { getFilteredApplicationsAll } from '../actions/ApplicationImpactsActions';
import { withOktaAuth } from '@okta/okta-react';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withStyles } from '@material-ui/core/styles';
import Application from '../component/Application';
import DataUtilities from '../services/DataUtilities';
import { getApplication } from '../actions/ApplicationImpactsActions';
import { getAppInteraction } from '../actions/ApplicationInteractionActions';
import { setApplicationInteractions } from '../actions/ApplicationInteractionActions';
import { setPageTitleAction } from '../actions/actions';

const PAGE_TITLE = 'Interactions';

var styles = () => ({
    drcSelect: {
        width: '300px',
        margin: '20px'
    },
    main: {
        marginTop: 60,
        '@media print': {
            marginTop: 0,
            boxShadow: 'none',
            borderRadius: 0
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            padding: 12
        }
    },
    sectionHeader: {
        border: 'none',
        paddingRight: 10,
        position: 'relative',
        height: 'calc(100% - 8px)',
        margin: '0 0 0 -2px',
        display: 'none',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            display: 'block',
            float: 'none',
            borderBottom: '1px solid ' + DuThemeUtilities.DefaultColors.primary.green,
            textAlign: 'left'
        }
    },
    sectionTitle: {
        zIndex: 1,
        position: 'relative',
        fontSize: '2rem',
        marginBottom: 8,
        textShadow: '-3px -3px 3px white, 3px -3px 3px white, 3px 3px 3px white, -3px 3px 3px white',
        '@media (prefers-color-scheme: dark)': {
            textShadow: '-3px -3px 3px black, 3px -3px 3px black, 3px 3px 3px black, -3px 3px 3px black'
        },
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            textShadow: 'none'
        }
    },
    appParent: {
        paddingBottom: 24,
        '--s': '106px' /* size */,
        '--m': '1px' /* margin */,
        '--f': 'calc(1.732 * var(--s) + 4 * var(--m) - 1px)'
    },
    appContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: 0,
        paddingLeft: '10px'
    },
    verticalBar: {
        borderRight: '2px solid ' + DuThemeUtilities.DefaultColors.primary.green,
        height: 'calc(100% - 25px)',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    deptRow: {
        display: 'flex',
        marginTop: 20,
        breakInside: 'avoid',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            flexDirection: 'column'
        },
        '& > div:first-of-type': {
            '@media print': {
                flexBasis: '16.66666667%',
                maxWidth: '16.66666667%'
            }
        },
        '& > div:nth-of-type(2)': {
            '@media print': {
                flexBasis: '83.33333333%',
                maxWidth: '83.33333333%'
            }
        }
    },
    mainAppBox: {
        paddingRight: '10px'
    }
});

const ApplicationInteractions = ({
    match,
    classes,
    getFilteredApplicationsAll,
    history,
    applicationInteractions,
    applicationData,
    pageTitle,
    setPageTitle,
    getApplication,
    getAppInteraction,
    appInteractionData,
    authState,
    setApplicationInteractions,
    filteredApplicationsAll
}) => {
    const [selectedApp, setSelectedApp] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [filteredInteractions, setFilTeredInteractions] = useState([]);
    const [filteredApplicationsByInteraction, setFilteredApplicationsByInteraction] = useState([]);

    useEffect(() => {
        if (pageTitle !== PAGE_TITLE) {
            setPageTitle(PAGE_TITLE);
        }

        //Get data if page is refreshed
        if (!appInteractionData || !appInteractionData.Results) {
            setIsLoading(true);
            DataUtilities.LOAD_INTERACTIONS_APPS_MASTER_DATA(
                { authState, getApplication, getAppInteraction, getFilteredApplicationsAll },
                () => {
                    setIsLoading(false);
                },
                (error) => {
                    console.error('LOAD_INTERACTIONS_APPS_MASTER_DATA::', error);
                    setIsLoading(false);
                }
            );
        }
        getParams();
    }, [match]);

    useEffect(() => {
        if ((!applicationInteractions || !applicationInteractions.length) && appInteractionData && appInteractionData.Results) {
            DataUtilities.CALCULATE_INTERACTIONS(appInteractionData, setApplicationInteractions);
        }
    }, [appInteractionData]);

    const getParams = () => {
        let appId = match.params?.appId || null;
        let appName = match.params?.appName || null;
        let selectedApp = null;
        if (appId && appName) {
            selectedApp = {
                value: appId,
                label: appName
            };
        }
        calculateApplicationFilteredByInteraction();
        calculateInteractions(selectedApp);
        setSelectedApp(selectedApp);
    };

    const calculateApplicationFilteredByInteraction = () => {
        const options = filteredApplicationsAll.filter((opt) => applicationInteractions.some((app) => app.app === opt.value));
        setFilteredApplicationsByInteraction(options);
    };

    const calculateInteractions = (option) => {
        if (option) {
            let filteredInteractions = applicationInteractions.filter((appInt) => appInt.app === Number.parseInt(option.value));
            setFilTeredInteractions(filteredInteractions);
            return;
        }
        setFilTeredInteractions(applicationInteractions);
    };

    const checkAppName = () => {
        let appId = match.params?.appId || null;
        let selectedAppObj = null;
        let selectedAppLabel = null;
        if (appId) {
            selectedAppLabel = applicationData?.Results?.find((item) => item.ApplicationMasterId == appId);
        }

        if (selectedAppLabel) {
            selectedAppObj = {
                value: appId,
                label: selectedAppLabel.Application
            };
        }
        setSelectedApp(selectedAppObj);
    };

    const handleAppChange = (option) => {
        setSelectedApp(() => option, checkAppName());

        if (option == null) {
            history.push('/ApplicationInteractions/');
            return;
        }
        let appLabel = option.label;
        appLabel = appLabel.replace(/\//g, '-');
        history.push(`/ApplicationInteractions/${option.value}/${appLabel}/`);
    };

    const getApp = (appId) => {
        let app = applicationData?.Results?.find((item) => item.ApplicationMasterId == appId);
        return app;
    };

    const buildMainApp = (id) => {
        const app = getApp(id);
        if (!app) {
            return;
        }
        return (
            <>
                <DrcLegend className={classes.sectionHeader}>
                    <div className={classes.sectionTitle}>{app.Application}</div>
                </DrcLegend>
                <div className={classes.verticalBar}>
                    <div className={classes.mainAppBox}>
                        <Application appItem={app} />
                    </div>
                </div>
            </>
        );
    };

    const applicationOnclick = (app) => {
        if (!app) {
            return;
        }
        history.push(`/ApplicationInteractions/${app.ApplicationMasterId}/${app.Application}/`);
    };

    return (
        <DrcMain className={classes.main}>
            <div style={{ display: isLoading ? 'block' : 'none' }}>
                <DrcLoading text={<h1>Loading Application Interactions</h1>} />
            </div>
            {!isLoading && (
                <div style={{ display: isLoading ? 'none' : 'block' }}>
                    <div className={classes.search}>
                        <DrcSelect
                            options={filteredApplicationsByInteraction}
                            onChange={handleAppChange}
                            label="Application"
                            className={classes.drcSelect}
                            placeholder="Select Application"
                            value={selectedApp}
                            isClearable={true}
                        />
                    </div>
                    {filteredInteractions.length > 0 ? (
                        filteredInteractions.map((fi) => {
                            return (
                                <div key={fi.app} className={`${classes.deptRow}`}>
                                    <div>{buildMainApp(fi.app)}</div>
                                    <div>
                                        <div className={classes.appParent}>
                                            <div className={classes.appContainer}>
                                                {(fi.interactions || []).map((appId, index) => {
                                                    const app = getApp(appId);
                                                    if (!app) {
                                                        return;
                                                    }
                                                    return <Application key={index} appItem={app} onClick={() => applicationOnclick(app)} isLink />;
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div>No Interactions</div>
                    )}
                </div>
            )}
        </DrcMain>
    );
};

export function mapStateToProps(state) {
    return {
        filteredApplicationsAll: state.ApplicationImpactsReducer.filteredApplicationsAll,
        applicationInteractions: state.AppInteractionReducer.applicationInteractions,
        applicationData: state.ApplicationImpactsReducer.applicationData,
        appInteractionData: state.AppInteractionReducer.appInteractionData
    };
}

const mapDispatchToProps = (dispatch) => ({
    getFilteredApplicationsAll: (data) => dispatch(getFilteredApplicationsAll(data)),
    getApplication: (data) => dispatch(getApplication(data)),
    getAppInteraction: (data) => dispatch(getAppInteraction(data)),
    setApplicationInteractions: (data) => dispatch(setApplicationInteractions(data)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(ApplicationInteractions)));
