import { CHANGE_FILTER } from '../actions/actions';

const applicationData = {
    displayOption: { value: 'App Landscapes', label: 'App Landscapes' },
    businessUnit: { value: 'All', label: 'All' }
};

const SelectedFilterReducer = (state = applicationData, action) => {
    switch (action.type) {
        case CHANGE_FILTER:
            return Object.assign({}, state, {
                ...state,
                ...action.payload.filter.filter
            });
        default:
            return state;
    }
};

export default SelectedFilterReducer;
