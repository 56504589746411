export const GET_APPLICATION = 'GET_APPLICATION';
export const GET_PROCESS_IMPACTS = 'GET_PROCESS_IMPACTS';
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_PROCESS = 'GET_PROCESS';
export const DEPT_PROCESS = 'DEPT_PROCESS';
export const FILTER_APPLICATIONS = 'FILTER_APPLICATIONS';
export const DEPT_APPS = 'DEPT_APPS'; // applications mapped to department
export const GET_TYPES = 'GET_TYPES';
export const GET_TYPE_LOOKUPS = 'GET_TYPE_LOOKUPS';
export const GET_APPLICATION_DETAILS_LEGENDS = 'GET_APPLICATION_DETAILS_LEGENDS'; //Get array of some applications applications 

export const getApplication = (applicationData) => ({
    type: GET_APPLICATION,
    payload: { applicationData }
});

export const getProcessImpacts = (processImpactsData) => ({
    type: GET_PROCESS_IMPACTS,
    payload: { processImpactsData }
});

export const getDepartments = (departmentData) => ({
    type: GET_DEPARTMENTS,
    payload: { departmentData }
});

export const getProcess = (processData) => ({
    type: GET_PROCESS,
    payload: { processData }
});

export const getDeptProcess = () => ({
    type: DEPT_PROCESS
});


export const getFilteredApplications = () => ({
    type: FILTER_APPLICATIONS
});

export const getDeptApplications = () => ({
    type: DEPT_APPS
});

export const getTypes = (typesData) => ({
    type: GET_TYPES,
    payload: { typesData }
});

export const getTypeLookups = (typeLookupsData) => ({
    type: GET_TYPE_LOOKUPS,
    payload: { typeLookupsData }
});

export const getApplicationsDetailLegends = (applicationDetailLegendsData) => ({
    type: GET_APPLICATION_DETAILS_LEGENDS,
    payload: { applicationDetailLegendsData }
});

export const FILTER_APPLICATIONS_ALL = 'FILTER_APPLICATIONS_ALL';
export const getFilteredApplicationsAll = () => ({
    type: FILTER_APPLICATIONS_ALL
});