import { Middleware } from '@driscollsinc/one-ring';
import APIEndPoints from '../services/api';

import Finance_Png from '../images/Finance.png';
import Finance_Webp from '../images/Finance.webp';
import GSF_Png from '../images/GSF.png';
import GSF_Webp from '../images/GSF.webp';
import HR_Png from '../images/HR.png';
import HR_Webp from '../images/HR.webp';
import Nursery_Png from '../images/Nursery.png';
import Nursery_Webp from '../images/Nursery.webp';
import Planning_Png from '../images/Planning.png';
import Planning_Webp from '../images/Planning.webp';
import RD_Png from '../images/R&D.png';
import RD_Webp from '../images/R&D.webp';
import Sales_Png from '../images/Sales.png';
import Sales_Webp from '../images/Sales.webp';
import Supply_Png from '../images/Supply.png';
import Supply_Webp from '../images/Supply.webp';

let DATA_LOADED_TIME = null;

class DataUtilities {
    static VALIDATE_RESULT = (data, apiName) => {
        let errors = [];

        if (!data || !data.TotalResults) {
            errors.push('Error for ' + apiName);
        } else if (data.TotalResults <= 0) {
            errors.push('No Data for ' + apiName);
        }

        return errors;
    };

    static INJECT_DEPARTMENT_PHOTOS = (departmentResponse) => {
        if (!departmentResponse.Results) {
            return departmentResponse;
        }

        let results = departmentResponse.Results.map((d) => {
            let imagePng = null;
            let imageWebp = null;

            switch (d.Name) {
                case 'Finance':
                    imagePng = Finance_Png;
                    imageWebp = Finance_Webp;
                    break;
                case 'GSF':
                    imagePng = GSF_Png;
                    imageWebp = GSF_Webp;
                    break;
                case 'HR & Payroll':
                    imagePng = HR_Png;
                    imageWebp = HR_Webp;
                    break;
                case 'Nursery':
                    imagePng = Nursery_Png;
                    imageWebp = Nursery_Webp;
                    break;
                case 'Planning':
                    imagePng = Planning_Png;
                    imageWebp = Planning_Webp;
                    break;
                case 'R&D':
                    imagePng = RD_Png;
                    imageWebp = RD_Webp;
                    break;
                case 'Sales & Mktg':
                    imagePng = Sales_Png;
                    imageWebp = Sales_Webp;
                    break;
                case 'Supply Chain':
                    imagePng = Supply_Png;
                    imageWebp = Supply_Webp;
                    break;
                default:
                    break;
            }

            return { ...d, imagePng, imageWebp };
        });

        return { ...departmentResponse, Results: results };
    };

    static LOAD_DATA = (props, successCallback, failureCallback) => {
        //If we have already done the load just call success
        if (DATA_LOADED_TIME && new Date() - DATA_LOADED_TIME < 60 * 60 * 1000) {
            if (successCallback) {
                successCallback();
            }

            return;
        }

        let token = props.authState?.accessToken;

        Promise.all([
            Middleware.SendRaw(token, APIEndPoints.APPLICATION, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.PROCESS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.DEPARTMENTS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.PROCESS_IMPACTS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.APPLICATION_INTERACTION, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.APPLICATION_DETAILS_LEGENDS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.TYPE_LOOKUPS, 'GET')
        ])
            .then((returnData) => {
                let errors = [
                    ...this.VALIDATE_RESULT(returnData[0], 'Applications'),
                    ...this.VALIDATE_RESULT(returnData[1], 'Processes'),
                    ...this.VALIDATE_RESULT(returnData[2], 'Departments'),
                    ...this.VALIDATE_RESULT(returnData[3], 'Process Impacts'),
                    ...this.VALIDATE_RESULT(returnData[4], 'Application Interactions'),
                    ...this.VALIDATE_RESULT(returnData[5], 'Application Details Legends'),
                    ...this.VALIDATE_RESULT(returnData[3], 'TypeLookups')
                ];

                if (errors.length > 0) {
                    errors.forEach((e) => console.error(e));
                    return;
                }

                props.getApplication(returnData[0]);
                props.getProcess(returnData[1]);
                props.getDepartments(this.INJECT_DEPARTMENT_PHOTOS(returnData[2]));
                props.getProcessImpacts(returnData[3]);
                props.getAppInteraction(returnData[4]);
                props.getApplicationsDetailLegends(returnData[5]);
                props.getTypeLookups(returnData[6]);

                props.getDeptProcess();
                props.getFilteredApplications();
                props.getFilteredApplicationsAll();
                props.getDeptApplications();

                props.setApplicationInteractions && this.CALCULATE_INTERACTIONS(returnData[4], props.setApplicationInteractions);

                if (successCallback) {
                    successCallback();
                }

                DATA_LOADED_TIME = new Date();
            })
            .catch((error) => {
                if (failureCallback) {
                    failureCallback(error);
                }
            });
    };

    static LOAD_PROCESS_MASTER_DATA = (props, successCallback, failureCallback) => {
        let token = props.authState?.accessToken;

        Promise.all([
            Middleware.SendRaw(token, APIEndPoints.PROCESS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.DEPARTMENTS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.TYPES, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.TYPE_LOOKUPS, 'GET')
        ])
            .then((returnData) => {
                let errors = [
                    ...this.VALIDATE_RESULT(returnData[0], 'Processes'),
                    ...this.VALIDATE_RESULT(returnData[1], 'Departments'),
                    ...this.VALIDATE_RESULT(returnData[2], 'Types'),
                    ...this.VALIDATE_RESULT(returnData[3], 'TypeLookups')
                ];

                if (errors.length > 0) {
                    errors.forEach((e) => console.error(e));
                    return;
                }

                props.getProcess(returnData[0]);
                props.getDepartments(returnData[1]);
                props.getTypes(returnData[2]);
                props.getTypeLookups(returnData[3]);

                if (successCallback) {
                    successCallback();
                }

                DATA_LOADED_TIME = new Date();
            })
            .catch((error) => {
                if (failureCallback) {
                    failureCallback(error);
                }
            });
    };

    static LOAD_PROCESS_APPS_MASTER_DATA = (props, successCallback, failureCallback) => {
        let token = props.authState?.accessToken;

        Promise.all([
            Middleware.SendRaw(token, APIEndPoints.PROCESS, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.APPLICATION, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.PROCESS_IMPACTS, 'GET')
        ])
            .then((returnData) => {
                let errors = [
                    ...this.VALIDATE_RESULT(returnData[0], 'Processes'),
                    ...this.VALIDATE_RESULT(returnData[1], 'Applications'),
                    ...this.VALIDATE_RESULT(returnData[2], 'Process Impacts')
                ];

                if (errors.length > 0) {
                    errors.forEach((e) => console.error(e));
                    return;
                }

                props.getProcess(returnData[0]);
                props.getApplication(returnData[1]);
                props.getProcessImpacts(returnData[2]);

                props.getFilteredApplications();

                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error) => {
                if (failureCallback) {
                    failureCallback(error);
                }
            });
    };

    static LOAD_INTERACTIONS_APPS_MASTER_DATA = (props, successCallback, failureCallback) => {
        let token = props.authState?.accessToken;

        Promise.all([
            Middleware.SendRaw(token, APIEndPoints.APPLICATION, 'GET'),
            Middleware.SendRaw(token, APIEndPoints.APPLICATION_INTERACTION, 'GET')
        ])
            .then((returnData) => {
                let errors = [
                    ...this.VALIDATE_RESULT(returnData[0], 'Applications'),
                    ...this.VALIDATE_RESULT(returnData[1], 'Application Interactions')
                ];

                if (errors.length > 0) {
                    errors.forEach((e) => console.error(e));
                    return;
                }

                props.getApplication(returnData[0]);
                props.getAppInteraction(returnData[1]);

                props.getFilteredApplicationsAll && props.getFilteredApplicationsAll();

                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error) => {
                if (failureCallback) {
                    failureCallback(error);
                }
            });
    };

    static CALCULATE_INTERACTIONS(appInteractionData, setApplicationInteractions) {
        //we are considering the interaction is bidirectional. therefore, if we have the interaction A -> B it means we also have B -> A
        let appInteractions = (appInteractionData.Results || []).reduce((acc, curr) => {
            acc.push({ appA: curr.ApplicationMasterId, appB: curr.Interaction });
            acc.push({ appA: curr.Interaction, appB: curr.ApplicationMasterId });
            return acc;
        }, []);

        //we group one by one and add the interactions related to each app, we remove duplicated interactions because they are bidirectional
        const applicationInteractions = appInteractions.reduce((acc, curr) => {
            const exists = acc.find((a) => a.app === curr.appA);
            if (exists) {
                const interactionExists = exists.interactions.find((i) => i === curr.appB);
                if (interactionExists) {
                    return acc;
                }
                exists.interactions.push(curr.appB);
                return acc;
            }
            acc.push({ app: curr.appA, interactions: [curr.appB] });
            return acc;
        }, []);

        setApplicationInteractions(applicationInteractions);
    }
}

export default DataUtilities;
