import { ADD_INTERACTIONS } from '../actions/actions';
import {
    GET_APPLICATION_INTERACTION,
    SET_APPLICATION_INTERACTIONS
} from '../actions/ApplicationInteractionActions';

const interactionData = {
    GSFHR: [],
    GSFFINANCE: [],
    SALESMARKETING: [],
    SUPPLYCHAIN: [],
    SUPPLYSOLNURSERY: [],
    RESEARCHDEV: [],
    GSFOTHERS: [],
    GSFIT: [],
    DAXR2: [],
    DAXR3: [],
    appInteractionData: [], // This data comes from the database
    applicationInteractions: [], // This data is calculated in AppInteractions.js and map each applications with their interactions
    filteredApplications: []

};

const AppInteractionReducer = (state = interactionData, action) => {
    switch (action.type) {
        case ADD_INTERACTIONS:
            return Object.assign({}, state, {
                GSFHR: [...(action.payload.data.data.GSFHR || [])],
                GSFFINANCE: [...(action.payload.data.data.GSFFinance || [])],
                SALESMARKETING: [...(action.payload.data.data.SalesDOTA || []), ...(action.payload.data.data.SalesDEMEA || [])],
                SUPPLYCHAIN: [
                    ...(action.payload.data.data['Supply ChainDOTA'] || []),
                    ...(action.payload.data.data['Supply ChainDOTA/DEMEA'] || []),
                    ...(action.payload.data.data['Supply Chainnull'] || [])
                ],
                SUPPLYSOLNURSERY: [...(action.payload.data.data.NurseryDOTA || []), ...(action.payload.data.data['Supply SolutionsDOTA'] || [])],
                RESEARCHDEV: [...(action.payload.data.data.RDRD || [])],
                GSFOTHERS: [...(action.payload.data.data.GSFOthers || [])],
                GSFIT: [...(action.payload.data.data.GSFIT || [])],
                DAXR2: [...(action.payload.data.data.DAXR2 || [])],
                DAXR3: [...(action.payload.data.data.DAXR3 || [])]
            });

        case GET_APPLICATION_INTERACTION:
            if (!action.payload.appInteractionData) {
                return state;
            }
            return Object.assign({}, state, {
                appInteractionData: action.payload.appInteractionData
            });
        case SET_APPLICATION_INTERACTIONS:
            return {...state, applicationInteractions: action.payload.applicationInteractions};
        default:
            return state;
    }
};

export default AppInteractionReducer;
