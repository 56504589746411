import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DrcSelect,
    DrcMain,
    DrcLegend,
    DrcLoading,
    DrcSwitch,
    DrcMediaQueries,
    DrcImage,
    DrcHexCard
} from '@driscollsinc/driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../actions/actions';
import {
    getApplication,
    getProcess,
    getDepartments,
    getProcessImpacts,
    getDeptProcess,
    getFilteredApplications,
    getDeptApplications,
    getFilteredApplicationsAll
} from '../actions/ApplicationImpactsActions';
import { getAppInteraction } from '../actions/ApplicationInteractionActions';
import { withOktaAuth } from '@okta/okta-react';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DataUtilities from '../services/DataUtilities';

const PAGE_TITLE = 'Impacts';

const PURPLE_LIGHT = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 85);
const PURPLE_MEDIUM = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 45);
const PURPLE_DARK = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 20);
const PINK_LIGHT = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 85);
const PINK_MEDIUM = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 45);
const PINK_DARK = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 20);

var styles = () => ({
    drcSelect: {
        width: '300px',
        margin: '20px'
    },
    main: {
        marginTop: 60,
        '@media print': {
            marginTop: 0,
            boxShadow: 'none',
            borderRadius: 0
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            padding: 12
        }
    },
    sectionHeader: {
        border: 'none',
        float: 'left',
        textAlign: 'right',
        paddingRight: 10,
        position: 'relative',
        height: 'calc(100% - 8px)',
        margin: '0 0 0 -2px',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            float: 'none',
            borderBottom: '1px solid ' + DuThemeUtilities.DefaultColors.primary.green,
            textAlign: 'left'
        }
    },
    sectionTitle: {
        zIndex: 1,
        position: 'relative',
        fontSize: '2rem',
        marginBottom: 8,
        textShadow: '-3px -3px 3px white, 3px -3px 3px white, 3px 3px 3px white, -3px 3px 3px white',
        '@media (prefers-color-scheme: dark)': {
            textShadow: '-3px -3px 3px black, 3px -3px 3px black, 3px 3px 3px black, -3px 3px 3px black'
        },
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            textShadow: 'none'
        }
    },
    sectionImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        marginLeft: 'calc(10px - 100%)',
        opacity: 0.4,
        objectFit: 'cover',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    sectionBlock: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        top: 0,
        boxShadow: 'inset 0px 0px 10px 10px white',
        marginLeft: -2,
        '@media (prefers-color-scheme: dark)': {
            boxShadow: 'inset 0px 0px 10px 10px black'
        },
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    regionHeader: {
        border: 'none',
        float: 'left',
        color: '#6e459e',
        fontSize: '30px'
    },
    mainPanel: {
        borderRadius: '4px',
        boxShadow: '0px 0px 4px 1px #b5b5b5',
        maxWidth: '100% !important',
        border: 'none'
    },
    processParent: {
        display: 'flex',
        paddingBottom: 24,
        '--s': '106px' /* size */,
        '--m': '1px' /* margin */,
        '--f': 'calc(1.732 * var(--s) + 4 * var(--m) - 1px)'
    },
    processContainer: {
        fontSize: 0,
        '&::before': {
            content: '""',
            width: 'calc(var(--s)/2 + var(--m))',
            float: 'left',
            height: '120%',
            shapeOutside: 'repeating-linear-gradient(#0000 0 calc(var(--f) - 3px), #000  0 var(--f))'
        }
    },
    actionButton: {
        width: '25px',
        height: '25px',
        float: 'right',
        minWidth: '25px'
    },
    verticalBar: {
        borderRight: '2px solid ' + DuThemeUtilities.DefaultColors.primary.green,
        height: 'calc(100% - 8px)',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    horizontalLine: {
        borderBottom: '1px solid #C0C0C0'
    },
    filters: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '@media print': {
            display: 'none'
        }
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    switchContainer: {
        width: 260,
        border: '1px solid #ddd',
        borderRadius: 8,
        margin: '0 4px'
    },
    switch: {
        float: 'left'
    },
    switchLabel: {
        lineHeight: '35px'
    },
    totalImpacts: {
        textAlign: 'left',
        margin: 8,
        fontWeight: 'bold'
    },
    impactTotal: {
        position: 'absolute',
        top: 6,
        right: 'calc(50% - 12px)',
        border: '1px solid #999',
        borderRadius: 12,
        backgroundColor: '#000',
        padding: '0 4px',
        color: '#fff',
        minWidth: 24,
        textAlign: 'center',
        lineHeight: 'normal'
    },
    deptRow: {
        marginTop: 20,
        breakInside: 'avoid',
        '& > div:first-of-type': {
            '@media print': {
                flexBasis: '16.66666667%',
                maxWidth: '16.66666667%'
            }
        },
        '& > div:nth-of-type(2)': {
            '@media print': {
                flexBasis: '83.33333333%',
                maxWidth: '83.33333333%'
            }
        }
    },
    primaryImpact: {
        background: 'radial-gradient(' + PINK_LIGHT + ' 55%, ' + PINK_MEDIUM + ' 100%)',
        '-webkit-print-color-adjust': 'exact',
        borderWidth: 2,
        '@media (prefers-color-scheme: dark)': {
            background: 'radial-gradient(' + PINK_DARK + ' 55%, ' + PINK_MEDIUM + ' 100%)'
        }
    },
    secondaryImpact: {
        background: 'radial-gradient(' + PURPLE_LIGHT + ' 55%, ' + PURPLE_MEDIUM + ' 100%)',
        '-webkit-print-color-adjust': 'exact',
        borderWidth: 2,
        '@media (prefers-color-scheme: dark)': {
            background: 'radial-gradient(' + PURPLE_DARK + ' 55%, ' + PURPLE_MEDIUM + ' 100%)'
        }
    },
    indicatorContainer: {
        float: 'right',
        textAlign: 'right',
        zIndex: 10,
        position: 'relative',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            float: 'left'
        }
    },
    indicator: {
        padding: '4px 8px',
        border: '1px solid #000',
        marginTop: -1,
        color: '#000',
        fontSize: '1rem',
        fontWeight: 'normal',
        '@media (prefers-color-scheme: dark)': {
            color: '#fff'
        },
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            float: 'left',
            marginRight: 8
        }
    },
    highIndicator: {
        borderColor: DuThemeUtilities.DefaultColors.primary.pink,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 75),
        '-webkit-print-color-adjust': 'exact',
        textAlign: 'center',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.pink), 25)
        }
    },
    lowIndicator: {
        borderColor: DuThemeUtilities.DefaultColors.primary.purple,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 85),
        '-webkit-print-color-adjust': 'exact',
        textAlign: 'center',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.purple), 15)
        }
    },
    totalIndicator: {
        '& > div': {
            float: 'left',
            marginTop: 0,
            marginLeft: 8
        }
    },
    appIcon: {
        position: 'absolute',
        right: 'calc(50% - 12px)',
        bottom: 4,
        fontSize: '2rem'
    },
    highIcon: {
        color: DuThemeUtilities.DefaultColors.primary.pink
    },
    lowIcon: {
        color: DuThemeUtilities.DefaultColors.primary.purple
    },
    noPrint: {
        '@media print': {
            display: 'none'
        }
    }
});

class ApplicationImpacts extends Component {
    constructor(props) {
        super(props);

        let appId = this.props.match.params?.appId || null;
        let appName = this.props.match.params?.appName || null;
        let selectedApp = null;

        if (appId && appName) {
            selectedApp = {
                value: appId,
                label: appName
            };
        }

        this.state = {
            selectedApp: selectedApp,
            selectedProcessImpacts: [],
            selectedDepartmentImpacts: [],
            isLoading: true,
            totalHighCount: 0,
            totalLowCount: 0,
            showAllDepartments: false,
            showAllProcesses: false
        };
    }

    componentDidMount = async () => {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }

        DataUtilities.LOAD_DATA(
            this.props,
            () => {
                this.calculateImpacts(this.state.selectedApp);
                this.checkAppName();
            },
            (error) => console.error('LOAD_DATA::', error)
        );
    };

    checkAppName = () => {
        const { applicationData } = this.props;
        let appId = this.props.match.params?.appId || null;

        let selectedAppObj = null;
        let selectedAppLabel = null;
        if (appId) {
            selectedAppLabel = applicationData?.Results?.find((item) => item.ApplicationMasterId == appId);
        }

        if (selectedAppLabel) {
            selectedAppObj = {
                value: appId,
                label: selectedAppLabel.Application
            };
        }
        this.setState({ selectedApp: selectedAppObj });
    };

    calculateImpacts = (selectedApp) => {
        if (selectedApp) {
            const { processImpactsData, deptProcessMergeData } = this.props;

            let totalHighCount = 0;
            let totalLowCount = 0;
            let processImpacts = processImpactsData.Results.filter((r) => r.ApplicationId == selectedApp.value);

            let departmentImpacts = deptProcessMergeData.map((d) => {
                let highCount = d.Processes.filter((p) => processImpacts.find((pi) => pi.ProcessId == p.ProcessId && pi.Impact == 1)).length;
                let lowCount = d.Processes.filter((p) => processImpacts.find((pi) => pi.ProcessId == p.ProcessId && pi.Impact == 2)).length;

                totalHighCount += highCount;
                totalLowCount += lowCount;

                return { DepartmentId: d.DepartmentId, highCount, lowCount };
            });

            this.setState({
                isLoading: false,
                selectedProcessImpacts: processImpacts,
                selectedDepartmentImpacts: departmentImpacts,
                totalHighCount,
                totalLowCount
            });
        } else {
            this.setState({ isLoading: false });
        }
    };

    renderImpactData = (process) => {
        const { classes, applicationData, processImpactsData } = this.props;
        const { selectedProcessImpacts, showAllProcesses, selectedApp } = this.state;

        let colorClass = '';
        let icon = null;
        let description = process.ProcessName;

        if (selectedProcessImpacts.length > 0 && selectedApp) {
            let impact = selectedProcessImpacts.find((i) => i.ProcessId === process.ProcessId);

            if (impact && impact.Impact === 1) {
                colorClass = classes.primaryImpact;
                icon = <ArrowUpwardIcon className={`${classes.appIcon} ${classes.highIcon}`} />;
            } else if (impact && impact.Impact === 2) {
                colorClass = classes.secondaryImpact;
                icon = <KeyboardArrowDownIcon className={`${classes.appIcon} ${classes.lowIcon}`} />;
            }

            if (impact && impact.Description) {
                description = (
                    <>
                        <div>{process.ProcessName}</div>
                        <hr />
                        <div>{impact.Description}</div>
                    </>
                );
            }
        }

        if (!showAllProcesses && colorClass.length <= 0 && selectedApp) {
            return { hide: true };
        }

        if (!selectedApp) {
            let impacts = processImpactsData?.Results.filter((i) => i.ProcessId === process.ProcessId);

            if (impacts.length > 0) {
                description = (
                    <>
                        <div>{process.ProcessName}</div>
                        <hr />
                        {impacts.map((i) => (
                            <div key={i.ProcessImpactId}>
                                <span>{i.Impact == 1 ? 'High - ' : 'Low - '}</span>
                                <span>
                                    {(applicationData.Results.find((ad) => ad.ApplicationMasterId == i.ApplicationId) || {}).Application ||
                                        'Unknown Application'}
                                </span>
                            </div>
                        ))}
                    </>
                );
                icon = <div className={classes.impactTotal}>{impacts.length}</div>;
            }
        }

        return {
            description,
            icon,
            colorClass,
            hide: false
        };
    };

    handleAppChange = (option) => {
        this.calculateImpacts(option);
        this.setState({ selectedApp: option }, this.checkAppName());

        if (option == null) {
            this.props.history.push('/ApplicationImpacts/');
            return;
        }

        let appLabel = option.label;
        appLabel = appLabel.replace(/\//g, '-');
        this.props.history.push(`/ApplicationImpacts/${option.value}/${appLabel}/`);
    };

    handleSwitchDepartmentChange = (e) => {
        this.setState({
            showAllDepartments: e.target.checked
        });
    };

    handleSwitchProcessChange = (e) => {
        this.setState({
            showAllProcesses: e.target.checked
        });
    };

    render() {
        const { classes, deptProcessMergeData, filteredApplications } = this.props;
        const { isLoading, selectedApp, selectedDepartmentImpacts, totalHighCount, totalLowCount, showAllDepartments, showAllProcesses } = this.state;

        return (
            <DrcMain className={classes.main}>
                <div style={{ display: isLoading ? 'block' : 'none' }}>
                    <DrcLoading text={<h1>Loading Application Process Impacts</h1>} />
                </div>
                <div style={{ display: isLoading ? 'none' : 'block' }}>
                    <div className={classes.filters}>
                        <div className={classes.switchContainer}>
                            <DrcSwitch
                                checked={showAllDepartments}
                                value={showAllDepartments}
                                onChange={this.handleSwitchDepartmentChange}
                                className={classes.switch}
                            />
                            <span className={classes.switchLabel}>Show Departments With No Impact</span>
                        </div>
                        <div className={classes.switchContainer}>
                            <DrcSwitch
                                checked={showAllProcesses}
                                value={showAllProcesses}
                                onChange={this.handleSwitchProcessChange}
                                className={classes.switch}
                            />
                            <span className={classes.switchLabel}>Show Processes With No Impact</span>
                        </div>
                    </div>
                    <hr className={classes.noPrint} />
                    <div className={classes.search}>
                        <DrcSelect
                            options={filteredApplications}
                            onChange={this.handleAppChange}
                            label="Application"
                            className={classes.drcSelect}
                            placeholder="Select Application"
                            value={selectedApp}
                            isClearable={true}
                        />
                        {totalHighCount + totalLowCount > 0 && selectedApp ? (
                            <div className={`${classes.indicatorContainer} ${classes.totalIndicator}`}>
                                <p className={classes.totalImpacts}>Total Impacts</p>
                                {totalHighCount > 0 ? (
                                    <div className={`${classes.indicator} ${classes.highIndicator}`}>{totalHighCount} High</div>
                                ) : null}
                                {totalLowCount > 0 ? <div className={`${classes.indicator} ${classes.lowIndicator}`}>{totalLowCount} Low</div> : null}
                            </div>
                        ) : null}
                    </div>
                    {deptProcessMergeData.map((dept) => {
                        let departmentImpact = selectedDepartmentImpacts.find((di) => di.DepartmentId == dept.DepartmentId) || {};

                        if (!showAllDepartments && departmentImpact.highCount <= 0 && departmentImpact.lowCount <= 0 && selectedApp) {
                            return null;
                        }

                        return (
                            <div className={`row ${classes.deptRow}`} key={dept.Name}>
                                <div className="col-xs-12 col-sm-2">
                                    <DrcLegend className={classes.sectionHeader}>
                                        {dept.imagePng ? (
                                            <>
                                                <DrcImage src={dept.imagePng} webp={dept.imageWebp} className={classes.sectionImage} />
                                                <div className={classes.sectionBlock}></div>
                                            </>
                                        ) : null}
                                        <div className={classes.sectionTitle}>{dept.Name}</div>
                                        <div className={classes.indicatorContainer}>
                                            {departmentImpact.highCount > 0 && selectedApp ? (
                                                <div className={`${classes.indicator} ${classes.highIndicator}`}>
                                                    {departmentImpact.highCount} High
                                                </div>
                                            ) : null}
                                            {departmentImpact.lowCount > 0 && selectedApp ? (
                                                <div className={`${classes.indicator} ${classes.lowIndicator}`}>{departmentImpact.lowCount} Low</div>
                                            ) : null}
                                        </div>
                                    </DrcLegend>
                                    <div className={classes.verticalBar}></div>
                                </div>
                                <div className="col-xs-12 col-sm-10">
                                    <div className={classes.processParent}>
                                        <div className={classes.processContainer}>
                                            {dept.Processes.map((process, index) => {
                                                let processRenderer = this.renderImpactData(process);

                                                if (processRenderer.hide) {
                                                    return;
                                                }

                                                return (
                                                    <DrcHexCard
                                                        tooltip
                                                        className={`${processRenderer.colorClass}`}
                                                        key={index}
                                                        icon={processRenderer.icon}
                                                        toolTipText={processRenderer.description}
                                                    >
                                                        {process.ProcessName}
                                                    </DrcHexCard>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </DrcMain>
        );
    }
}

export function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        applicationData: state.ApplicationImpactsReducer.applicationData,
        applicationListOptions: state.ApplicationImpactsReducer.applicationListOptions,
        processData: state.ApplicationImpactsReducer.processData,
        departmentData: state.ApplicationImpactsReducer.departmentData,
        processImpactsData: state.ApplicationImpactsReducer.processImpactsData,
        deptProcessMergeData: state.ApplicationImpactsReducer.deptProcessMergeData,
        filteredApplications: state.ApplicationImpactsReducer.filteredApplications
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    getApplication: (data) => dispatch(getApplication(data)),
    getProcess: (data) => dispatch(getProcess(data)),
    getDepartments: (data) => dispatch(getDepartments(data)),
    getProcessImpacts: (data) => dispatch(getProcessImpacts(data)),
    getAppInteraction: (data) => dispatch(getAppInteraction(data)),
    getDeptProcess: (data) => dispatch(getDeptProcess(data)),
    getFilteredApplicationsAll: (data) => dispatch(getFilteredApplicationsAll(data)),
    getFilteredApplications: (data) => dispatch(getFilteredApplications(data)),
    getDeptApplications: (data) => dispatch(getDeptApplications(data))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationImpacts)));
