import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { DrcSelect, DrcMain, DrcLoading, DrcMediaQueries, DrcTabs } from '@driscollsinc/driscolls-react-components';
import AppLandscape from './AppLandscape';
import IntegratedApps from './IntegratedApps';
import AppInteractions from './AppInteractions';
import { addData, addInteractions, changeFilter, setPageTitleAction } from '../actions/actions';
import {
    getApplication,
    getProcess,
    getDepartments,
    getProcessImpacts,
    getDeptProcess,
    getFilteredApplications,
    getDeptApplications,
    getApplicationsDetailLegends,
    getTypeLookups,
    getFilteredApplicationsAll
} from '../actions/ApplicationImpactsActions';
import { setApplicationInteractions } from '../actions/ApplicationInteractionActions';
import { getAppInteraction } from '../actions/ApplicationInteractionActions';

import DataUtilities from '../services/DataUtilities';

const PAGE_TITLE = 'Landscapes';
const tabs = [
    {
        tabName: 'App Landscape',
        tabIndex: 0,
        component: AppLandscape,
        route: '/AppLandscape/'
    },
    {
        tabName: 'Integrated Apps',
        tabIndex: 1,
        component: IntegratedApps,
        route: '/IntegratedApps/'
    },
    {
        tabName: 'App Interactions',
        tabIndex: 2,
        component: AppInteractions,
        route: '/AppInteractions/'
    }
];

var styles = () => ({
    drcSelect: {
        width: '300px',
        marginLeft: 20
    },
    main: {
        marginTop: 60,
        '@media print': {
            marginTop: 0,
            boxShadow: 'none',
            borderRadius: 0
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            padding: 12
        }
    },
    search: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

class Directory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDept: null,
            isLoading: true,
            appData: null
        };
    }

    componentDidMount = () => {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }

        DataUtilities.LOAD_DATA(
            this.props,
            () => {
                this.saveData();
            },
            (error) => console.error('LOAD_DATA::', error)
        );
    };

    checkSelectedDeptName = () => {
        let deptName = this.props.location.pathname.split('/')[3] || null;
        let selectedDepartment = this.props.filters.businessUnit;
        if (deptName) {
            selectedDepartment = {
                label: deptName,
                value: deptName
            };
        }
        this.setState({ selectedDept: selectedDepartment });
    };

    saveData = async () => {
        let data = await this.props.applicationData;

        var dataObj = {};
        var dataObjInt = {};

        if (data.Results) {
            data.Results.map((item) => {
                if (dataObj[item.Department + item.BU] !== undefined) {
                    dataObj[item.Department + item.BU].push(item);
                } else {
                    dataObj[item.Department + item.BU] = [];
                    dataObj[item.Department + item.BU].push(item);
                }
            });
            this.props.addData(dataObj);

            data.Results.map((item) => {
                if (item.Application === 'DAX R3' || item.Application === 'DAX R2') {
                    if (dataObjInt[item.Application.replace(/ +/g, '')] !== undefined) {
                        dataObjInt[item.Application.replace(/ +/g, '')][0].Numbers.push(item.Interaction);
                    } else {
                        dataObjInt[item.Application.replace(/ +/g, '')] = [];
                        dataObjInt[item.Application.replace(/ +/g, '')].push({
                            Application: item.Application,
                            Numbers: [item.Interaction],
                            Type: item.Type,
                            DIS: item.DIS,
                            Infrastructure: item.Infrastructure
                        });
                    }
                } else {
                    if (dataObjInt[item.Department + item.BU] !== undefined) {
                        var found = false;
                        var idx;
                        for (var i = 0; i < dataObjInt[item.Department + item.BU].length; i++) {
                            if (dataObjInt[item.Department + item.BU][i].Application === item.Application) {
                                found = true;
                                idx = i;
                                break;
                            }
                        }
                        if (found) {
                            dataObjInt[item.Department + item.BU][idx].Numbers.push(item.Interaction);
                        } else {
                            dataObjInt[item.Department + item.BU].push({
                                Application: item.Application,
                                Numbers: [item.Interaction],
                                Type: item.Type,
                                DIS: item.DIS,
                                Infrastructure: item.Infrastructure
                            });
                        }
                    } else {
                        dataObjInt[item.Department + item.BU] = [];
                        dataObjInt[item.Department + item.BU].push({
                            Application: item.Application,
                            Numbers: [item.Interaction],
                            Type: item.Type,
                            DIS: item.DIS,
                            Infrastructure: item.Infrastructure
                        });
                    }
                }
            });

            this.props.addInteractions(dataObjInt);
            this.setState({ isLoading: false, appData: dataObj });
        } else {
            console.log('Error fetching applications data');
            this.setState({ isLoading: false });
        }
    };

    handleChangeFilter = async (value) => {
        this.props.changeFilter({ businessUnit: value });
        this.setState({ selectedDept: value });
        // if (value == null) {
        //     this.props.history.push('/Landscapes/AppLandscape/All');
        //     return;
        // }
        // if (this.props.location.pathname.includes('AppLandscape')) {
        //     this.props.history.push(`/Landscapes/AppLandscape/${value.label}/`);
        // } else if (this.props.location.pathname.includes('IntegratedApps')) {
        //     this.props.history.push(`/Landscapes/IntegratedApps/${value.label}/`);
        // } else {
        //     this.props.history.push(`/Landscapes/AppInteractions/${value.label}/`);
        // }
    };

    render() {
        const { classes, departmentNamesList, match } = this.props;
        const { isLoading } = this.state;
        return (
            <DrcMain className={classes.main}>
                <div style={{ display: isLoading ? 'block' : 'none' }}>
                    <DrcLoading text={<h1>Loading Application Landscapes</h1>} />
                </div>
                <div style={{ display: isLoading ? 'none' : 'block' }}>
                    <div className={classes.search}>
                        <DrcSelect
                            value={this.props.filters.businessUnit}
                            label="Department"
                            hiddenLabel={false}
                            isCreatable={true}
                            options={departmentNamesList}
                            onChange={this.handleChangeFilter}
                            className={classes.drcSelect}
                        />
                        <DrcTabs menuItems={tabs} centered baseLocation="/Landscapes" />
                        {match.params.type == 'AppLandscape' || match.params.type == '' ? <AppLandscape /> : null}
                        {match.params.type == 'IntegratedApps' ? <IntegratedApps /> : null}
                        {match.params.type == 'AppInteractions' ? <AppInteractions /> : null}
                    </div>
                </div>
            </DrcMain>
        );
    }
}

export function mapStateToProps(state) {
    return {
        filters: state.SelectedFilterReducer,
        pageTitle: state.rootReducer.pageTitle,
        applicationData: state.ApplicationImpactsReducer.applicationData,
        appInteractionData: state.AppInteractionReducer.appInteractionData,
        departmentNamesList: state.ApplicationImpactsReducer.departmentNamesList
    };
}

const mapDispatchToProps = (dispatch) => ({
    addData: (data) => dispatch(addData({ data })),
    addInteractions: (data) => dispatch(addInteractions({ data })),
    changeFilter: (filter) => dispatch(changeFilter({ filter })),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    getApplication: (data) => dispatch(getApplication(data)),
    getProcess: (data) => dispatch(getProcess(data)),
    getDepartments: (data) => dispatch(getDepartments(data)),
    getProcessImpacts: (data) => dispatch(getProcessImpacts(data)),
    getAppInteraction: (data) => dispatch(getAppInteraction(data)),
    getDeptProcess: (data) => dispatch(getDeptProcess(data)),
    getFilteredApplications: (data) => dispatch(getFilteredApplications(data)),
    getFilteredApplicationsAll: (data) => dispatch(getFilteredApplicationsAll(data)),
    getDeptApplications: (data) => dispatch(getDeptApplications(data)),
    getApplicationsDetailLegends: (data) => dispatch(getApplicationsDetailLegends(data)),
    getTypeLookups: (data) => dispatch(getTypeLookups(data)),
    setApplicationInteractions: (data) => dispatch(setApplicationInteractions(data))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Directory)));
