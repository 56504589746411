export const DisplayOptions = [
    { value: 'App Landscapes', label: 'App Landscapes' },
    { value: 'Integrated Apps', label: 'Integrated Apps' },
    { value: 'App Interactions', label: 'App Interactions' }
];

export const DepartmentOptions = [
    { value: 'All', label: 'All' },
    { value: 'GSFIT', label: 'GSF IT' },
    { value: 'GSFHR', label: 'GSF HR' },
    { value: 'GSFFINANCE', label: 'GSF Finance' },
    { value: 'SALESMARKETING', label: 'Sales & Marketing' },
    { value: 'SUPPLYCHAIN', label: 'Supply Chain' },
    { value: 'SUPPLYSOLNURSERY', label: 'Supply Solutions & Nursery' },
    { value: 'RESEARCHDEV', label: 'Research and Development' },
    { value: 'GSFOTHERS', label: 'GSF Others' }
];
