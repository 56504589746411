class APIEndPoints {
    static BASE = window.config.AWS_BASE_ADDRESS;
    static APP_LAND_INFO = this.BASE + 'appinfo/appmd';
    static APP_LAND_INTERACTIONS = this.BASE + 'appinfo/interactions';

    static APPLICATION = this.BASE + 'Applications/';
    static PROCESS_IMPACTS = this.BASE + 'ProcessImpacts/';
    static DEPARTMENTS = this.BASE + 'Departments/';
    static PROCESS = this.BASE + 'Processes/';
    static APPLICATION_INTERACTION = this.BASE + 'ApplicationInteraction/';
    static TYPES = this.BASE + 'Types/';
    static TYPE_LOOKUPS = this.BASE + 'TypeLookups/';
    static APPLICATION_DETAILS_LEGENDS = this.BASE + 'ApplicationDetailsLegends/';
}

export default APIEndPoints;
