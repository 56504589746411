import { combineReducers } from 'redux';
import AppMasterReducer from './AppMasterReducer.js';
import SelectedFilterReducer from './SelectedFilterReducer.js';
import AppInteractionReducer from './AppInteractionReducer.js';
import ApplicationImpactsReducer from './ApplicationImpactsReducer.js';

import {
    SHOW_LOADING_SCREEN,
    HIDE_LOADING_SCREEN,
    SET_COMMON_DIALOG,
    HIDE_ERROR_DIALOG,
    SET_PAGE_TITLE,
    SHOW_TOAST,
    SERVICE_WORKER_UPDATED,
    SERVICE_WORKER_UPDATE,
    SHOW_LOADING_BACKDROP,
    HIDE_LOADING_BACKDROP
} from '../actions/actions';

const initialState = {
    showLoadingBackdrop: false,
    showLoadingScreen: false,
    serviceWorkerUpdated: false,
    pageTitle: '',
    commonDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Error'
    },
    loadingScreenMessage:'Loading...',
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: (action.payload || '').length > 0 ? 'AppLand: ' + action.payload : 'AppLand'
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SHOW_LOADING_BACKDROP:
            return {
                ...state,
                showLoadingBackdrop: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_BACKDROP:
            return {
                ...state,
                showLoadingBackdrop: false,
                loadingScreenMessage: ''
            };
        case SET_COMMON_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    isMessage: !!action.payload.isMessage
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                isSuccess: action.payload.isSuccess
            };
        case SERVICE_WORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: true,
                serviceWorkerRegistration: action.payload
            };
        case SERVICE_WORKER_UPDATED:
            return {
                ...state,
                serviceWorkerUpdated: false
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    AppMasterReducer,
    SelectedFilterReducer,
    AppInteractionReducer,
    ApplicationImpactsReducer
});
