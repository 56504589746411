import React, { Component } from 'react';
import BorderWrapper from './BorderWrapper';
import { Settings } from '@material-ui/icons';
import MuleLogo from '../images/muleSoft.png';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { headerData } from '../data/HeaderData';
import { DrcLegend, DrcTooltip, DrcMediaQueries } from '@driscollsinc/driscolls-react-components';

var styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
        textAlign: 'center'
    },
    commonStyle: {
        borderRadius: 6,
        border: '2px solid #C0C0C0',
        padding: '10px',
        textAlign: 'center',
        marginBottom: '10px'
    },
    muleStyle: {
        fontWeight: 'bold',
        borderRadius: 6,
        backgroundColor: '#C0C0C0',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#333'
        }
    },
    muleLogoStyle: {
        height: '15px',
        width: '15px',
        marginLeft: '10px'
    },
    appTile: {
        borderRadius: 6,
        border: '2px solid #C0C0C0',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        justifyContent: 'center',
        minWidth: '100px !important',
        maxWidth: '100px !important',
        marginBottom: '8px',
        height: '60px',
        backgroundColor: '#eee',
        flex: 1,
        margin: '0 4px',
        padding: 8,
        position: 'relative',
        '@media print': {
            boxShadow: 'none'
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            minWidth: '140px !important',
            maxWidth: '140px !important'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#222',
            boxShadow: 'none'
        }
    },
    appTileLabel: {
        textAlign: 'center',
        fontWeight: 700
    },
    sectionHeader: {
        border: 'none',
        paddingRight: '10px'
    },
    processContainer: {
        width: '30%',
        borderRadius: 6,
        border: '1px solid #C0C0C0',
        backgroundColor: '#DDD',
        margin: '10px',
        ['@media screen and ' + DrcMediaQueries.mobileL]: {
            width: '90%'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#111'
        }
    }
});
class BUHeader extends Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                {headerData.map((item) => {
                    return (
                        <div className={classes.processContainer} key={item.Name}>
                            <div>
                                <DrcLegend className={classes.sectionHeader}>{item.Name}</DrcLegend>
                            </div>
                            <div className={classes.container}>
                                {item.Processes.map((name, index) => {
                                    return (
                                        <div className={`${classes.appTile}`} key={index}>
                                            <DrcTooltip>
                                                <span className={classes.appTileLabel}>{name.ProcessName}</span>
                                            </DrcTooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}

                <div>
                    {this.props.intLayer !== 3 && (
                        <BorderWrapper className={`${classes.muleStyle} ${classes.commonStyle}`}>
                            {(this.props.intLayer === 1 || 2) && <Settings style={{ marginRight: 10 }} />}
                            Middleware = Driscolls Intergration Layer / Mulesoft
                            {this.props.intLayer === 1 && <img src={MuleLogo} className={classes.muleLogoStyle} alt="MuleLogo" />}
                        </BorderWrapper>
                    )}
                </div>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        filters: state.SelectedFilterReducer,
        data: state.AppInteractionReducer
    };
}

export default connect(mapStateToProps)(withStyles(styles)(BUHeader));
