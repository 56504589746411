import React, { Component } from 'react';
import { withRouter } from 'react-router';
import BorderWrapper from './BorderWrapper';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import Application from './Application';

var styles = () => ({
    cotsTile: {
        borderWidth: 2,
        borderColor: DuThemeUtilities.DefaultColors.primary.green,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 75),
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 15)
        }
    },
    containerStyle: {
        borderWidth: '1px',
        borderRadius: 6,
        borderStyle: 'solid',
        padding: '5px 0',
        textAlign: 'center',
        breakInside: 'avoid',
        '@media print': {
            paddingBottom: '40px',
            border: 'none'
        },
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px'
    },
    commonStyle: {
        borderRadius: 6,
        borderStyle: 'solid',
        padding: '5px 0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },

    customStyle: {
        borderWidth: 2,
        borderColor: DuThemeUtilities.DefaultColors.primary.yellow,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.yellow), 85),
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.yellow), 15)
        }
    },
    section: {
        borderColor: DuThemeUtilities.DefaultColors.primary.green,
        backgroundColor: '#DDD',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#111'
        }
    },
    title: {
        fontWeight: 'bold',
        borderColor: '#888',
        backgroundColor: '#EEE',
        margin: '5px',
        padding: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#222'
        },
        '@media print': {
            padding: '15px',
            margin: '0px',
            border: 'none',
            fontSize: '20px'
        }
    },
    applicationsStyle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        breakInside: 'avoid'
    },



});

class Department extends Component {

    render() {
        const { classes, getToolTips, navigateToDetails, appData, getRelatedApp } = this.props;

        var applications = [];
        appData.forEach((item, idx) => {
            let relatedApp = getRelatedApp(item.ApplicationMasterId);
            const toolTips = getToolTips(relatedApp);
            applications.push(
                <Application
                    key={item.Application + idx}
                    appItem={item}
                    onClick={() => navigateToDetails(item, relatedApp)}
                    relatedItem={relatedApp}
                    toolTips={toolTips}
                />
            );
        });

        return (
            <BorderWrapper className={`${classes.section} ${classes.containerStyle}`}>
                <div className={`${classes.title} ${classes.commonStyle}`}>{this.props.title}</div>

                <div className={classes.applicationsStyle}>{applications}</div>
            </BorderWrapper>
        );
    }
}

export function mapStateToProps(state) {
    return {
        filters: state.SelectedFilterReducer
    };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Department)));
