import { ADD_DATA } from '../actions/actions';

const applicationData = {
    GSFHR: [],
    GSFFINANCE: [],
    SALESMARKETING: [],
    SUPPLYCHAIN: [],
    SUPPLYSOLNURSERY: [],
    RESEARCHDEV: [],
    GSFOTHERS: [],
    GSFIT: []
};

const AppMasterReducer = (state = applicationData, action) => {
    switch (action.type) {
        case ADD_DATA:
            return Object.assign({}, state, {
                // GSFHR: [...(action.payload.data.data.GSFHR || [])],
                // GSFFINANCE: [...(action.payload.data.data.GSFFinance || [])],
                // SALESMARKETING: [...action.payload.data.data.SalesDOTA, ...(action.payload.data.data.SalesDEMEA || [])],
                // SUPPLYCHAIN: [
                //     ...action.payload.data.data['Supply ChainDOTA'],
                //     ...action.payload.data.data['Supply ChainDOTA/DEMEA'],
                //     ...(action.payload.data.data['Supply Chainnull'] || [])
                // ],
                // SUPPLYSOLNURSERY: [...action.payload.data.data.NurseryDOTA, ...(action.payload.data.data['Supply SolutionsDOTA'] || [])],
                // RESEARCHDEV: [...(action.payload.data.data.RDRD || [])],
                // GSFOTHERS: [...(action.payload.data.data.GSFOthers || [])],
                // GSFIT: [...(action.payload.data.data.GSFIT || [])]
            });
        default:
            return state;
    }
};

export default AppMasterReducer;
