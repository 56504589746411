// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    toolbar: {
        landScapes: ['LandScapes', 'Paisajes terrestres', '风景', 'Paysages'],
        Flows: ['Flows', 'Flujos', '流量', 'Les flux']
    },
    HelpTexts: {
        addNewType: ['Add new', 'Añadir nuevo', '新增', 'Ajouter un nouveau'],
        editType: ['Edit', 'Editar', '编辑', 'Modifier'],
        changeTypeConfirmTitle: ['Change Type?', '¿Cambiar tipo?', '更改类型？', 'Changer le type?'],
        addNewTypeConfirmTitle: ['Add new?', '¿Añadir nuevo?', '新增？', 'Ajouter un nouveau?'],
        deleteTypeConfirmTitle: ['Delete?', '¿Eliminar?', '删除？', 'Supprimer?']
    }
};

export default APP_TRANSLATIONS;
