import React, { Component } from 'react';

class MiniDB extends Component {
    render() {
        return (
            <div
                style={{
                    width: 50,
                    position: 'relative',
                    backgroundColor: this.props.color,
                    borderRadius: '28%',
                    marginRight: this.props.marginRight,
                    marginLeft: this.props.marginLeft
                }}
            >
                <div
                    style={{
                        borderWidth: '0 2px',
                        borderStyle: 'solid',
                        position: 'absolute',
                        top: '13%',
                        width: '100%',
                        bottom: '9%',
                        borderColor: this.props.borderColor
                    }}
                />
                <div
                    style={{
                        borderWidth: 2,
                        height: 10,
                        borderRadius: '50%',
                        width: '100%',
                        borderStyle: 'solid',
                        borderColor: this.props.borderColor
                    }}
                />
                <div
                    style={{
                        marginTop: -3,
                        borderBottomWidth: 2,
                        height: 10,
                        borderRadius: '50%',
                        width: '100%',
                        borderBottomStyle: 'solid',
                        borderColor: this.props.borderColor
                    }}
                />
                <div
                    style={{
                        marginTop: 10,
                        borderBottomWidth: 2,
                        height: 10,
                        borderRadius: '50%',
                        width: '100%',
                        borderBottomStyle: 'solid',
                        borderColor: this.props.borderColor
                    }}
                />
            </div>
        );
    }
}

export default MiniDB;
