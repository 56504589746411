export const headerData = [
    {
        Name: 'Microsoft DAX R2-DEMEA',
        Processes: [
            { ProcessName: 'Account Payable' },
            { ProcessName: 'Account Receivable' },
            { ProcessName: 'General Accounting' },
            { ProcessName: 'Order Management' },
            { ProcessName: 'Quality Assurance' },
            { ProcessName: 'Supply Planning' }
        ]
    },
    {
        Name: 'Microsoft DAX R3-DOTA',
        Processes: [
            { ProcessName: 'Account Payable' },
            { ProcessName: 'Account Receivable' },
            { ProcessName: 'General Accounting' },
            { ProcessName: 'Grower Settlement' },

            { ProcessName: 'Order Management' }
        ]
    },
    {
        Name: 'Net Suite',
        Processes: [{ ProcessName: 'Account Payable' }, { ProcessName: 'Account Receivable' }, { ProcessName: 'Supply Planning' }]
    }
];
