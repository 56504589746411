import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { Grid } from '@material-ui/core';
import { WebAsset, Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { DrcSelect } from '@driscollsinc/driscolls-react-components';

import { addData, addInteractions, changeFilter, setPageTitleAction } from '../actions/actions';

// import DIS from '../component/flowItems/DIS';
import MiniDB from '../component/flowItems/MiniDB';
// import Flow from '../component/flowItems/Flow';

const PAGE_TITLE = 'Flows';

var styles = (theme) => ({
    drcSelect: {
        width: 300,
        marginLeft: 10
    },
    container: {
        margin: '100px',
        border: '2px solid'
    },
    extAppWrapper: {
        borderTop: '2px solid',
        padding: '10px'
    },
    appStyle: {
        margin: 'auto 0',
        fontWeight: 'bold'
    },
    appTitle: {
        margin: 'auto 5px auto 0',
        lineHeight: '0.1em'
    },
    appLink: {
        width: 100,
        textAlign: 'center',
        borderBottom: '1px solid #000',
        lineHeight: '0.1em',
        margin: 'auto 0'
    },
    etlBackground: {
        background: '#fff',
        padding: '0 10px'
    },
    description: {
        margin: 'auto 0'
    }
});

const legends = [
    { name: 'AX/DAX', definition: 'Microsoft Dynamics' },
    { name: 'AXREST', definition: 'Microsoft Dynamics Integration Service' },
    { name: 'MDR', definition: 'Master Data Repository' },
    { name: 'DB', definition: 'Database' },
    { name: 'DIS', definition: "Driscoll's Integration Service" },
    { name: 'DMAIS', definition: "Driscoll's Mobile Adaptor Integration Service" },
    { name: 'DFSS', definition: "Driscoll's Food Safety Service" },
    { name: 'DPS', definition: "Driscoll's Printing Service" },
    { name: 'DMDS', definition: "Driscoll's Master Data Service" },
    { name: 'DMS', definition: "Driscoll's Messaging Service" }
];

class ApplicationFlows extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.pageTitle !== PAGE_TITLE) {
            this.props.setPageTitle(PAGE_TITLE);
        }
    }

    render() {
        const legendData = legends.map((legend) => {
            return <div key={legend.name}>{`${legend.name} : ${legend.definition}`}</div>;
        });
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <Grid container direction="row">
                    <Grid item lg={4}>
                        <Grid container justify="center">
                            <DrcSelect
                                value={{ value: 'Global Breeding Application', label: 'Global Breeding Application' }}
                                options={[{ value: 'Global Breeding Application', label: 'Global Breeding Application' }]}
                                label="Application"
                                className={classes.drcSelect}
                            />
                        </Grid>
                        <div className={classes.extAppWrapper}>
                            <h2>Externally Linked Applications</h2>
                            <Grid container justify="center">
                                <Grid container direction="row">
                                    <WebAsset style={{ fontSize: 60 }} />
                                    <div className={classes.appStyle}>
                                        <Grid container direction="row">
                                            <div className={classes.appTitle}>Gator</div>
                                            <div className={classes.appLink}>
                                                <span className={classes.etlBackground}>ETL</span>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid container direction="row">
                                    <WebAsset style={{ fontSize: 60 }} />
                                    <div className={classes.appStyle}>
                                        <Grid container direction="row">
                                            <div className={classes.appTitle}>Seedling</div>
                                            <div className={classes.appLink}>
                                                <span className={classes.etlBackground}>ETL</span>
                                            </div>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    {/* <div style={{ border: '2px solid', padding: '1%', width: '75%' }}>
                        <Flow />
                    </div> */}
                </Grid>
                <Grid container direction="row" style={{ border: '2px solid', padding: '5px' }}>
                    <Grid item xs={12} sm={12} lg={4}>
                        <div style={{ fontWeight: 'bold' }}>Legends and Definitions</div>
                        <div style={{ margin: 10 }}>{legendData}</div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                        {/* <ArcherContainer style={{ width: '100%', marginLeft: -10 }} strokeColor="black">
                            <Grid container direction="row">
                                <div style={{ marginRight: '25%', width: '25%' }}>
                                    <ArcherElement
                                        id="Initiator"
                                        relations={[
                                            {
                                                targetId: 'Response',
                                                targetAnchor: 'left',
                                                sourceAnchor: 'right'
                                            }
                                        ]}
                                    >
                                        <DIS name="Initiator" width="100%" />
                                    </ArcherElement>
                                </div>
                                <div style={{ marginLeft: '25%', width: '25%' }}>
                                    <ArcherElement id="Response"><DIS name="Response" width="100%" /></ArcherElement>
                                </div>
                            </Grid>
                        </ArcherContainer> */}

                        <div style={{ margin: 15 }}>
                            Interaction lines start from the initiator of the Get/Post and resolve w/arrow at the responding end service/app/function.
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={4}>
                        <Grid container direction="row">
                            <WebAsset style={{ fontSize: 60 }} />
                            <span className={classes.description}>Application</span>
                        </Grid>
                        <Grid container direction="row">
                            <MiniDB color="rgba(0, 118, 165, .5)" borderColor="rgb(0, 118, 165)" />
                            <span className={classes.description}>
                                The Application with this icon is indicates the use of a application service log to store and share application
                                service call detail.
                            </span>
                        </Grid>
                        <Grid container direction="row">
                            <MiniDB color="rgba(111, 80, 145, .5)" borderColor="rgb(111, 80, 145)" />
                            <span className={classes.description}>
                                The application with this icon is connected to a logging service to save transactional detail.
                            </span>
                        </Grid>
                        <Grid container direction="row">
                            <Settings style={{ fontSize: 60 }} />
                            <span className={classes.description}>
                                The application with this icon indicate the use of Driscolls integration services to communicate. Applications without
                                this icon use other methods for communicating between applications. Point to point, for example.
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        filters: state.SelectedFilterReducer,
        pageTitle: state.rootReducer.pageTitle
    };
}

const mapDispatchToProps = (dispatch) => ({
    addData: (data) => dispatch(addData({ data })),
    addInteractions: (data) => dispatch(addInteractions({ data })),
    changeFilter: (filter) => dispatch(changeFilter({ filter })),
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationFlows));
