import {
    GET_APPLICATION,
    GET_PROCESS_IMPACTS,
    GET_DEPARTMENTS,
    GET_PROCESS,
    DEPT_PROCESS,
    FILTER_APPLICATIONS,
    DEPT_APPS,
    GET_TYPES,
    GET_TYPE_LOOKUPS,
    GET_APPLICATION_DETAILS_LEGENDS,
    FILTER_APPLICATIONS_ALL
} from '../actions/ApplicationImpactsActions';

const initialState = {
    applicationData: [],
    applicationListOptions: [],
    processData: [],
    departmentData: [],
    processImpactsData: [],
    deptProcessMergeData: [],
    filteredApplications: [], // applications with no processes,
    deptListOptions: [],
    impactsSeverity: [
        { value: 1, label: 'High' },
        { value: 2, label: 'Low' }
    ],
    dataRecordStatus: [
        { value: 1, label: 'Active' },
        { value: 0, label: 'InActive' }
    ],
    //DIS
    disStatus: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
    ],
    businessUnits: [
        { value: 'DANZ', label: 'DANZ' },
        { value: 'DEMEA', label: 'DEMEA' },
        { value: 'DOC', label: 'DOC' },
        { value: 'DOTA', label: 'DOTA' }
    ],
    processListOptions: [],
    departmentNamesList: [],
    deptAppData: [],
    typesData: [],
    typeLookupsData: [],
    applicationDetailLegendsData: {},
    filteredApplicationsAll: []
};

const ApplicationImpactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_APPLICATION: {
            if (!action.payload.applicationData) {
                return state;
            }
            const applicationOptions = action.payload.applicationData.Results.map((appName) => ({
                value: appName.ApplicationMasterId,
                label: appName.Application
            }));
            return Object.assign({}, state, {
                applicationData: action.payload.applicationData,
                applicationListOptions: applicationOptions
            });
        }
        case GET_PROCESS_IMPACTS: {
            if (!action.payload.processImpactsData) {
                return state;
            }

            return Object.assign({}, state, {
                processImpactsData: action.payload.processImpactsData
            });
        }

        case GET_DEPARTMENTS: {
            if (!action.payload.departmentData) {
                return state;
            }
            const departmentOptions = action.payload.departmentData.Results.map((deptName) => ({
                value: deptName.DepartmentId,
                label: deptName.Name
            }));
            const departmentNames = action.payload.departmentData.Results.map((dept) => ({
                value: dept.Name,
                label: dept.Name
            }));
            departmentNames.unshift({ value: 'All', label: 'All' });
            return Object.assign({}, state, {
                departmentData: action.payload.departmentData,
                deptListOptions: departmentOptions,
                departmentNamesList: departmentNames
            });
        }

        case GET_PROCESS: {
            if (!action.payload.processData) {
                return state;
            }
            const processOptions = action.payload.processData.Results.map((po) => ({
                value: po.ProcessId,
                label: po.Name
            }));

            return Object.assign({}, state, {
                processData: action.payload.processData,
                processListOptions: processOptions
            });
        }
        case DEPT_PROCESS: {
            const processData = state.processData.Results.sort((a, b) => a.Name.localeCompare(b.Name));
            const departmentData = state.departmentData;

            const processesNameFilter = processData.reduce((processObj, value) => {
                if (!processObj[value.DepartmentId]) {
                    processObj[value.DepartmentId] = [];
                }

                processObj[value.DepartmentId].push({ ProcessName: value.Name, ProcessId: value.ProcessId });

                return processObj;
            }, {});

            var deptProcessData = departmentData.Results.map((obj) => ({
                ...obj,
                Processes: processesNameFilter[obj.DepartmentId]
            }));

            deptProcessData = deptProcessData.filter((processesArray) => {
                return processesArray.Processes !== undefined && processesArray.Processes.length !== 0;
            });

            return Object.assign({}, state, {
                deptProcessMergeData: deptProcessData.sort((a, b) => a.Name.localeCompare(b.Name))
            });
        }
        case FILTER_APPLICATIONS_ALL: {
            const applicationData = state.applicationData.Results;

            const applicationOptionsFiltered = applicationData
                .map((appName) => ({
                    value: appName.ApplicationMasterId,
                    label: appName.Application
                }))
                .sort((a, b) => a.label.localeCompare(b.label));

            return Object.assign({}, state, {
                filteredApplicationsAll: applicationOptionsFiltered
            });
        }
        case FILTER_APPLICATIONS:
            const processImpactsData = state.processImpactsData;
            const applicationData = state.applicationData;

            const filterApplicationsResult = applicationData.Results.filter(function (app) {
                return processImpactsData.Results.some(function (p) {
                    return app.ApplicationMasterId === p.ApplicationId;
                });
            });

            const applicationOptionsFiltered = filterApplicationsResult
                .map((appName) => ({
                    value: appName.ApplicationMasterId,
                    label: appName.Application
                }))
                .sort((a, b) => a.label.localeCompare(b.label));

            return Object.assign({}, state, {
                filteredApplications: applicationOptionsFiltered
            });
        case DEPT_APPS: {
            let applicationData = state.applicationData.Results;
            const departmentData = state.departmentData;

            applicationData = applicationData.filter((appArray) => {
                return appArray.DepartmentId !== 'NULL';
            });

            applicationData = applicationData.filter((appArray) => {
                return appArray.DepartmentId !== null;
            });
            const deptAppMergeData = applicationData.reduce((appObj, value) => {
                if (!appObj[value.DepartmentId]) {
                    appObj[value.DepartmentId] = [];
                }

                appObj[value.DepartmentId].push({
                    Application: value.Application,
                    ApplicationMasterId: value.ApplicationMasterId,
                    DepartmentId: value.DepartmentId
                });

                return appObj;
            }, {});

            let deptAppData = departmentData.Results.map((obj) => ({
                DepartmentId: obj.DepartmentId,
                DepartmentName: obj.Name,
                Applications: deptAppMergeData[obj.DepartmentId]
            }));

            deptAppData = deptAppData.filter((app) => app.Applications !== undefined); //filter data which doesn't have applications

            return Object.assign({}, state, {
                deptAppMergeData,
                deptAppData
            });
        }

        case GET_TYPES: {
            if (!action.payload.typesData) {
                return state;
            }

            return Object.assign({}, state, {
                typesData: action.payload.typesData
            });
        }

        case GET_TYPE_LOOKUPS: {
            if (!action.payload.typeLookupsData) {
                return state;
            }

            return Object.assign({}, state, {
                typeLookupsData: action.payload.typeLookupsData
            });
        }

        case GET_APPLICATION_DETAILS_LEGENDS: {
            if (!action.payload.applicationDetailLegendsData) {
                return state;
            }

            return Object.assign({}, state, {
                applicationDetailLegendsData: action.payload.applicationDetailLegendsData
            });
        }

        default:
            return state;
    }
};

export default ApplicationImpactsReducer;
