import React from 'react';
import { DrcImage } from '@driscollsinc/driscolls-react-components';
import { DisplayOptions } from '../data/defaults';
import { Cloud, Settings } from '@material-ui/icons';
import MuleLogoPng from '../images/muleSoft.png';
import MuleLogoWebp from '../images/muleSoft.webp';
import AwsLogoPng from '../images/AWS.png';
import AwsLogoWebp from '../images/AWS.webp';
import Interaction from './Interaction';
import { withStyles } from '@material-ui/core/styles';

import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const styles = () => ({
    commonStyle: {
        borderRadius: 6,
        borderStyle: 'solid',
        padding: '5px 0',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    appsContainer: {
        height: '70px',
        margin: '5px',
        width: '100px'
    },
    muleIcon: {
        width: 17,
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: 1
    },
    settingsIcon: {
        position: 'absolute',
        bottom: 0,
        left: 0
    },
    cloudIcon: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: '0 3px',
        fill: 'rgb(0,118,165)'
    },
    appLink: {
        cursor: 'pointer'
    },
    applicationName: {
        borderWidth: 2,
        borderColor: DuThemeUtilities.DefaultColors.primary.blue,
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 80),
        '-webkit-print-color-adjust': 'exact',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.blue), 15)
        }
    },
    mainApp: {
        position: 'absolute'
    }
});

const Application = ({ classes, isMainApp = false, appItem, onClick, relatedItem = [], toolTips, isLink = false }) => {
    const buildAppTemplate = () => {
        if (appItem.IsCots === true) {
            return (
                <div
                    className={`${classes.commonStyle} ${classes.customStyle} ${classes.appsContainer} ${
                        relatedItem.length > 0 || isLink ? classes.appLink : null
                    } ${isMainApp ? classes.mainApp : null}`}
                    onClick={onClick}
                >
                    {appItem.HasMuleApi && <DrcImage src={MuleLogoPng} webp={MuleLogoWebp} className={classes.muleIcon} alt="MuleLogo" />}
                    {appItem.HasAwsApi && <DrcImage src={AwsLogoPng} webp={AwsLogoWebp} className={classes.muleIcon} alt="AWS" />}
                    {appItem.HasDisIntegration === true && <Settings className={classes.settingsIcon} />}
                    {appItem.IsInAws === true && <Cloud className={classes.cloudIcon} />}
                    <span>{appItem.Application}</span>
                    {this.props.filters.displayOption.value === DisplayOptions[2] && (
                        <div>
                            <Interaction numbers={appItem.Numbers} />
                        </div>
                    )}
                    {toolTips}
                </div>
            );
        } else if (appItem.IsCustom === true) {
            return (
                <div
                    className={`${classes.commonStyle} ${classes.customStyle} ${classes.appsContainer} ${
                        relatedItem.length > 0 || isLink ? classes.appLink : null
                    } ${isMainApp ? classes.mainApp : null}`}
                    onClick={onClick}
                >
                    {appItem.HasMuleApi && <DrcImage src={MuleLogoPng} webp={MuleLogoWebp} className={classes.muleIcon} alt="MuleLogo" />}
                    {appItem.HasAwsApi && <DrcImage src={AwsLogoPng} webp={AwsLogoWebp} className={classes.muleIcon} alt="AWS" />}
                    {appItem.HasDisIntegration === true && <Settings className={classes.settingsIcon} />}
                    {appItem.IsInAws === true && <Cloud className={classes.cloudIcon} />}
                    <span>{appItem.Application}</span>
                    {this.props.filters.displayOption.value === DisplayOptions[2] && (
                        <div>
                            <Interaction numbers={appItem.Numbers} />
                        </div>
                    )}
                    {toolTips}
                </div>
            );
        } else {
            return (
                <div
                    className={`${classes.commonStyle} ${classes.applicationName} ${classes.appsContainer} ${
                        relatedItem.length > 0 || isLink ? classes.appLink : null
                    } ${isMainApp ? classes.mainApp : null}`}
                    onClick={onClick}
                >
                    <span>{appItem.Application}</span>
                    {toolTips}
                </div>
            );
        }
    };

    return buildAppTemplate();
};

export default withStyles(styles)(Application);
