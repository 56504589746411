import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

var styles = () => ({
    numberStyle: {
        borderWidth: '1px',
        borderRadius: '15px',
        width: '20px',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderStyle: 'solid',
        borderColor: 'rgb(111, 80, 145)',
        backgroundColor: 'rgba(111, 80, 145, .8)',
        color: '#FBF6EA',
        textAlign: 'center'
    }
});

class Interaction extends Component { //TODO remove if it is not needed
    render() {
        return <div>Interaction</div>    ;
        //TODO: Need to have the Interactions Column created for this data to work
        /*const { classes, margin } = this.props;
        var nbrList = [];

        this.props.numbers.forEach(function (item, idx) {
            nbrList.push(
                <div
                    key={'Interaction' + idx}
                    style={{
                        margin: margin || 0
                    }}
                    className={classes.numberStyle}
                >
                    {item}
                </div>
            );
        });
        return <div>{nbrList}</div>;*/
    }
}

export default withStyles(styles)(Interaction);
