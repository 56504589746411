import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import BUHeader from '../component/BUHeader';
import Department from '../component/Department';
import Legend from '../component/Legend';
import { withStyles } from '@material-ui/core/styles';
import { DrcTooltip } from '@driscollsinc/driscolls-react-components';
import { DisplayOptions } from '../data/defaults';
import { changeFilter } from '../actions/actions';
import { setApplicationInteractions } from '../actions/ApplicationInteractionActions';
import DataUtilities from '../services/DataUtilities';

var styles = () => ({
    impactTooltip: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: -8,
        border: '1px solid #999',
        borderRadius: 8,
        padding: '0 4px',
        color: '#fff',
        background: '#000'
    }
});
class AppInteractions extends Component {
    componentDidMount() {
        this.props.changeFilter({ ...this.props.filters, displayOption: DisplayOptions[2] });
        this.calculateInteractions();
    }

    calculateInteractions() {
        const { appInteractionData, setApplicationInteractions } = this.props;
        DataUtilities.CALCULATE_INTERACTIONS(appInteractionData, setApplicationInteractions);
    }

    getToolTips = (app) => {
        if (app.length && app[0] && app[0].interactions) {
            return (
                <DrcTooltip className={this.props.classes.impactTooltip}>
                    <div>{app[0].interactions.length}</div>
                </DrcTooltip>
            );
        }
        return null;
    };

    getAppName = (appId) => {
        let appName = this.props.applicationData?.Results?.find((item) => item.ApplicationMasterId == appId);
        return appName?.Application;
    };

    navigateToDetails = (item, data) => {
        if (!data || !data.length || !data[0] || !data[0].interactions) {
            return;
        }
        let appLabel = item.Application;
        appLabel = appLabel.replace(/\//g, '-');
        this.props.history.push(`/ApplicationInteractions/${item.ApplicationMasterId}/${appLabel}/`);
    };

    render() {
        const { deptAppData, applicationInteractions } = this.props;
        let BUs;
        if (this.props.filters.businessUnit.value === 'All') {
            BUs =
                deptAppData &&
                deptAppData.map((deptName) => {
                    const deptValue = deptName.Applications || [];
                    const name = deptName.DepartmentName;
                    return (
                        <Department
                            navigateToDetails={this.navigateToDetails}
                            getToolTips={this.getToolTips}
                            title={name}
                            key={name}
                            appData={deptValue}
                            margin="10px 2px 10px 0"
                            getRelatedApp={(appId) => applicationInteractions.filter((relatedData) => relatedData.app === appId)}
                        />
                    );
                });
        } else {
            let filteredData = deptAppData.filter((deptName) => deptName.DepartmentName === this.props.filters.businessUnit.value);
            BUs =
                filteredData &&
                filteredData.map((deptName) => {
                    const deptValue = deptName.Applications || [];
                    const name = deptName.DepartmentName;

                    return (
                        <Department
                            navigateToDetails={this.navigateToDetails}
                            getToolTips={this.getToolTips}
                            key={name}
                            title={this.props.filters.businessUnit.label}
                            appData={deptValue}
                            margin="10px 2px 10px 0"
                            getRelatedApp={(appId) => applicationInteractions.filter((relatedData) => relatedData.app === appId)}
                        />
                    );
                });
        }

        return (
            <div>
                <BUHeader intLayer={3} />
                {BUs}
                <Legend margin="10px 0 10px 2px" />
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        deptAppData: state.ApplicationImpactsReducer.deptAppData,
        filters: state.SelectedFilterReducer,
        data: state.AppMasterReducer,
        appInteractionData: state.AppInteractionReducer.appInteractionData,
        applicationData: state.ApplicationImpactsReducer.applicationData,
        applicationInteractions: state.AppInteractionReducer.applicationInteractions
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: (filter) => dispatch(changeFilter(filter)),
        setApplicationInteractions: (data) => dispatch(setApplicationInteractions(data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppInteractions)));
